@import "scss/variables";


@font-face {
  font-family: 'Journal';
  src: url("../public/font/journal.ttf") format('truetype');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url("../public/font/Raleway-Regular.otf");
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url("../public/font/Raleway-Bold.otf");
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #222222;

  background: url("../public/img/bg_1600.jpg") 0 0 no-repeat fixed $primary;
  background-size: cover;

  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $secondary;
  text-decoration:none;

  &:hover {
    color: $secondaryLight;
  }
}

h1 {
  margin:0;
  font-size: 28px;
  font-family: "Raleway-Regular", sans-serif;
  font-weight: 700;
}

h2 {
    font-size: 22px;
    font-family: "Raleway-Regular", sans-serif;
    font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
