@import "../../scss/variables";

.price-block {
  width: 100%;

  table {
    width: 100%;

    tr{
      width: 100%;
      background-color: rgba($secondary, 15%);

      &:nth-child(even) {
        background-color: transparent;
      }

      td {
        padding: 10px 15px;
      }

      .price-block-item-label-col {
        min-width: 100px;
        width: auto;
        word-break: break-word;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }

      .price-block-item-sub-label-col {
        min-width: 70px;
        width: 200px;
      }

      .price-block-item-price-col {
        min-width: 70px;
        width: 100px;
        text-align: right;
      }
    }
  }
}
