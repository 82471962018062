$primary: #fef59a;
$secondary: #9e0d16;
$secondaryLight: lighten($secondary, 20%);

$primaryRgba: rgba(254,245,154,1);
$SecondaryRgba: rgba(158,13,22,1);

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$spacer: 1rem;
