@import "src/scss/variables";

.responsive-logo {

  background: $primary;
  display: flex;
  justify-content: space-between;
  padding: $spacer;

  border-top: 5px solid $secondary;
  border-bottom: 5px solid $secondary;


  .logo-left {
    max-height: 140px;
    max-width: 70%;
    width: 300px;
  }

  .logo-right {
    max-height: 140px;
    max-width: 30%;
  }
}

