@import "src/scss/variables";

.footer-container {
  width: 100%;
  height: auto;
  margin: 3px auto 10px;
  text-align: center;

  .footer-button {
    padding: 5px 10px;
    background: $secondary;
    color: $primary;

    font-size: 0.9rem;

    &.active {
      background-color: $primary;
      color: $secondary;
    }

    &:hover {
      background: $primary;
      color: $secondary;
    }

    &:last-child {
      margin-left: 5px;
    }
  }
}
