@import "./scss/variables";

.app {

  max-width: $lg;
  margin: 0 auto;

  .content-container {
    background-color: $primary;
    padding: 15px;
    border-top: 5px solid $secondary;
    border-bottom: 5px solid $secondary;
    margin-top: 5px;

    @media (min-width: $sm) {
      padding: 30px;
    }
  }
}


