@import "../../scss/variables";

.nav-bar {

  margin-top: 5px;

  ul {
    font-size: 1.3rem;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;

    @media (min-width: $sm) {
      flex-direction: row;
    }

    li {
      flex: 1;
      text-align: center;
      background-color: $secondary;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $primary;

      &:last-child {
        border-bottom: none;
      }

      @media (min-width: $sm) {
        border-bottom: none;
      }

      a {
        display: block;
        padding: 10px 0;
        width: 100%;
        color: $primary;

        &.active {
          background-color: $primary;
          color: $secondary;
        }
      }

      &:hover {
        background-color: $primary;

        a {
          color: $secondary;
        }
      }
    }
  }
}
