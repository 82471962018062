@import "src/scss/variables";

.contact-root {
  display: flex;
  flex-direction: column;

  @media (min-width: $sm) {
    flex-direction: row;
    justify-content: space-between;

  }

  .contact-details {
    margin-bottom: $spacer;
    margin-right: 0;

    @media (min-width: $sm) {
      margin-bottom: 0;
      margin-right: $spacer;
    }
  }

  .contact-map {
    flex: 1 1 auto;

    .contact-map-image {
      width: 100%;

    }
  }
}

.map-container {
  width: 100%;

}
